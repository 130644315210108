<template>
  <b-card>
    <b-alert show variant="primary">
      <b-icon-info-circle class="mr-1"/> All theme settings are stored locally in your internet browser, not on the server.
    </b-alert>

    <b-row class="customizer-section">
      <b-col cols="12" class="mb-1">
        <b-form-group label="Skin">
          <b-form-radio-group
              id="skin-radio-group"
              v-model="theme.skin"
              name="skin"
              :options="options.skins"
          />
        </b-form-group>
      </b-col>
    </b-row>


    <!-- SECTION: Menu -->
    <div class="customizer-section">

      <!-- Layout Type -->
      <b-form-group label="Menu Layout">
        <b-form-radio-group
            v-model="theme.type"
            name="layout-type"
            :options="options.layouts"
        />
      </b-form-group>
    </div>

    <!-- SECTION: Navbar -->
    <div class="customizer-section">

      <!-- Navbar Color -->
      <b-form-group v-show="theme.type === 'vertical'" label="Navbar Color">
        <div
            v-for="(color, index) in options.navbar.backgrounds.bootstrap" :key="color"
            v-b-tooltip.hover.top :title="color"
            class="p-1 d-inline-block rounded mr-1 cursor-pointer"
            :class="[`bg-${color}`, {'border border-light': !index}, {'mark-active': theme.navbar.backgroundColor === color}]"
            @click="theme.navbar.backgroundColor = color"
        /><br/>
        <div
            v-for="(color, index) in options.navbar.backgrounds.other" :key="color"
            v-b-tooltip.hover.bottom :title="color"
            class="p-1 d-inline-block rounded mr-1 cursor-pointer"
            :class="[`bg-${color}`, {'border border-light': !index}, {'mark-active': theme.navbar.backgroundColor === color}]"
            @click="theme.navbar.backgroundColor = color"
        />
      </b-form-group>

      <!-- Navbar Type -->
      <b-form-group :label="theme.type === 'vertical' ? 'Navbar Type' : 'Menu Type'" class="">
        <b-form-radio-group v-model="theme.navbar.type" :options="options.navbar.types" />
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      theme: {
        type: this.$store.state.appConfig.layout.type,
        skin: this.$store.state.appConfig.layout.skin,
        navbar: {
          type: this.$store.state.appConfig.layout.navbar.type,
          backgroundColor: this.$store.state.appConfig.layout.navbar.backgroundColor,
        }
      },
      options: {
        skins: [
          { text: 'Light', value: 'light' },
          { text: 'Bordered', value: 'bordered' },
          { text: 'Dark', value: 'dark' },
          { text: 'Semi Dark', value: 'semi-dark' },
        ],
        layouts: [
          { text: 'Vertical', value: 'vertical' },
          { text: 'Horizontal', value: 'horizontal' },
        ],
        transitions: [
          { title: 'Zoom Fade', value: 'zoom-fade' },
          { title: 'Fade', value: 'fade' },
          { title: 'Fade Bottom', value: 'fade-bottom' },
          { title: 'Slide Fade', value: 'slide-fade' },
          { title: 'Zoom Out', value: 'zoom-out' },
          { title: 'None', value: 'none' },
        ],
        navbar: {
          types: [
            { text: 'Floating', value: 'floating' },
            { text: 'Sticky', value: 'sticky' },
            { text: 'Static', value: 'static' },
            /*{ text: 'Hidden', value: 'hidden' },*/
          ],
          backgrounds: {
            bootstrap: [
              'white', 'light',
              'light-primary', 'primary',
              'light-secondary', 'secondary',
              'light-success', 'success',
              'light-danger', 'danger',
              'light-warning', 'warning',
              'light-info', 'info',
              'dark', 'black'
            ],
            other: [
              'flickr', 'foursquare', 'adn', 'openid', 'odnoklassniki', 'soundcloud', 'reddit', 'google', 'pinterest',
              'yahoo', 'vimeo', 'twitter', 'dropbox', 'microsoft', 'linkedin', 'vk', 'instagram', 'facebook', 'bitbucket', 'tumblr', 'github',
            ]
          }
        }
      }
    }
  },
  watch: {
    theme: {
      deep: true,
      handler() {
        this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', this.theme.type)
        this.$store.commit('appConfig/UPDATE_SKIN', this.theme.skin)
        this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', this.theme.navbar)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
</style>
