<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="passwordChange" tag="form">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input v-model="user.username" placeholder="Username" name="username" readonly/>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <validation-provider v-slot="validationContext" vid="email" name="Email Address" :rules="rules.email">
            <b-form-group label="E-mail" label-for="account-e-mail" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
              <b-form-input v-model="user.email" disabled name="email" placeholder="Email"/>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <validation-provider v-slot="validationContext" vid="phone-number" name="Phone Number" :rules="rules.phone">
            <b-form-group label="Phone Number" label-for="phone-input">
              <b-form-input id="phone-input" v-model="user.phone" v-mask="'+1 (###) ###-####'" :state="getValidationState(validationContext)"></b-form-input>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="updateAttributes"
          >
            Save changes
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1"
              variant="outline-secondary"
              @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import {VueMaskDirective} from 'v-mask';
import {Auth} from 'aws-amplify';
import notify from '@/mixins/notify.mixin';

export default {
  components: {
    vSelect,
    flatPickr,
    Cleave,
  },
  directives: {
    Ripple,
    mask: VueMaskDirective
  },
  mixins: [ notify ],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      currentUser: {
        attributes: { }
      },
      user: {
        id: this.informationData.id,
        username: this.informationData.username,
        email: this.informationData.email,
        phone: this.informationData.phone,
      },
      rules: {
        email: { required: true, email: true },
        phone: { required: false, min: 17 }, //17, so that we get the +1, spaces and parenthesis
      },
      icon: 'fas fa-info-circle',
      error: {
        text: null,
        visible: false
      },
      state: {
        updating: true
      },
    }
  },
  computed: {
    awsPhoneNumber() {
      return this.user.phone?.replaceAll(/[^+\d]+/g, '')
    },
  },
  async mounted() {
    const user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    this.currentUser.attributes = attributes
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateAttributes() {
      this.state.updating = true
      await this.$refs?.passwordChange?.validate().then((isValid) => {
        if(isValid) {
          Auth.currentAuthenticatedUser()
              .then(user => Auth.updateUserAttributes(user, {
                /*email: this.user.email,*/
                phone_number: this.awsPhoneNumber
              }))
              .then(data => {
                this.$emit('update:phone', this.awsPhoneNumber)
                this.notify({ title: 'Success', text: 'Attributes updated', icon: this.icon, variant: 'success'});
              })
              .catch(err => {
                console.error(err)
                this.error.text = err
                this.error.visible = true
                this.notify({ title: 'Error', text: 'Attributes failed to update', icon: this.icon, variant: 'danger'});
              })
        }
        else {
          this.notify({ title: 'Warning', text: 'Missing required fields', icon: this.icon, variant: 'warning'});
        }
      })
      this.state.updating = false
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
