<template>
  <b-row class="px-2">
    <b-col>
      <b-row>
        <b-col cols="12" align-self="center" class="avatar-wrapper">
          <avataaars ref="avatar-maker"
                     :is-circle="avatar.isCircle"
                     :circle-color="avatar.circleColor"
                     :skin-color="avatar.skinColor"
                     :top-type="avatar.topType"
                     :top-color="avatar.topColor"
                     :hair-color="avatar.topColor"
                     :accessories-type="avatar.accessoriesType"
                     :eye-type="avatar.eyeType"
                     :eyebrow-type="avatar.eyebrowType"
                     :mouth-type="avatar.mouthType"
                     :facial-hair-type="avatar.facialHairType"
                     :facial-hair-color="avatar.facialHairColor"
                     :clothe-type="avatar.clotheType"
                     :clothe-color="avatar.clotheColor"
                     :graphic-type="avatar.graphicType"

          />
        </b-col>
        <b-col cols="12" align-self="center" class="d-flex justify-content-center mt-2">
          <b-button class="mr-1" @click="random()">
            Random
            <font-awesome-icon icon="fa-solid fa-dice" />
          </b-button>
          <b-button variant="primary" class="mr-1" @click="setAvatar()">
            Use
            <font-awesome-icon icon="fa-regular fa-circle-check" />
          </b-button>
          <b-button variant="outline-primary"  @click="save()">
            Download
            <font-awesome-icon icon="fa-solid fa-download" />
          </b-button>
        </b-col>
      </b-row>
    </b-col>
    <b-col>
      <b-row class="bg-light-primary rounded p-1 my-1">
        <b-col>
          <b-form-group label="Skin Color" label-class="font-small-3">
            <b-select v-model="avatar.skinColor" :options="options.skinColors"></b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="bg-light-primary rounded p-1 my-1">
        <b-col>
          <b-form-group label="Hair" label-class="font-small-3">
            <b-select v-model="avatar.topType" :options="options.topTypes"></b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Hair Color" label-class="font-small-3">
            <b-select v-model="avatar.topColor" :options="hairOrHatColors" :disabled="hairColorDisabled"></b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="bg-light-primary rounded p-1 my-1">
        <b-col>
          <b-form-group label="Eyes" label-class="font-small-3">
            <b-select v-model="avatar.eyeType" :options="options.eyeTypes"></b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Eyebrows" label-class="font-small-3">
            <b-select v-model="avatar.eyebrowType" :options="options.eyebrowTypes"></b-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Glasses" label-class="font-small-3">
            <b-select v-model="avatar.accessoriesType" :options="options.accessoriesTypes"></b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="bg-light-primary rounded p-1 my-1">
        <b-col>
          <b-form-group label="Mouth" label-class="font-small-3">
            <b-select v-model="avatar.mouthType" :options="options.mouthTypes"></b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="bg-light-primary rounded p-1 my-1">
        <b-col>
          <b-form-group label="Facial Hair" label-class="font-small-3">
            <b-select v-model="avatar.facialHairType" :options="options.facialHairTypes"></b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Facial Hair Color" label-class="font-small-3">
            <b-select v-model="avatar.facialHairColor" :options="options.hairColors"></b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="bg-light-primary rounded p-1 my-1">
        <b-col>
          <b-form-group label="Shirt" label-class="font-small-3">
            <b-select v-model="avatar.clotheType" :options="options.clotheTypes"></b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Shirt Color" label-class="font-small-3">
            <b-select v-model="avatar.clotheColor" :options="options.clotheColors"></b-select>
          </b-form-group>
        </b-col>
        <b-col v-if="avatar.clotheType === 'GraphicShirt'" cols="12">
          <b-form-group label="Shirt Graphic" label-class="font-small-3">
            <b-select v-model="avatar.graphicType" :options="options.graphicTypes"></b-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Avataaars from 'vuejs-avataaars'

export default {
  name: 'AvatarMaker',
  components: {
    Avataaars
  },
  data() {
    return {
      avatar: {
        isCircle: true,
        circleColor: '#212969',
        topType: 'random',
        topColor: 'random',
        accessoriesType: 'Blank',
        facialHairType: 'random',
        clotheType: 'random',
        eyeType: 'random',
        eyebrowType: 'random',
        mouthType: 'random',
        skinColor: 'random',
        graphicType: 'random',
        hairColor: 'random',
        facialHairColor: 'random',

        clotheColor: 'random',
      },
      options: {
        topTypes: [
          'NoHair',
          'Eyepatch',
          'Hat',
          'Hijab',
          'Turban',
          'WinterHat1',
          'WinterHat2',
          'WinterHat3',
          'WinterHat4',
          'LongHairBigHair',
          'LongHairBob',
          'LongHairBun',
          'LongHairCurly',
          'LongHairCurvy',
          'LongHairDreads',
          'LongHairFrida',
          'LongHairFro',
          'LongHairFroBand',
          'LongHairNotTooLong',
          'LongHairShavedSides',
          'LongHairMiaWallace',
          'LongHairStraight',
          'LongHairStraight2',
          'LongHairStraightStrand',
          'ShortHairDreads01',
          'ShortHairDreads02',
          'ShortHairFrizzle',
          'ShortHairShaggyMullet',
          'ShortHairShortCurly',
          'ShortHairShortFlat',
          'ShortHairShortRound',
          'ShortHairShortWaved',
          'ShortHairSides',
          'ShortHairTheCaesar',
          'ShortHairTheCaesarSidePart'
        ],
        topColors: [
          'Auburn',
          'Black',
          'Blonde',
          'BlondeGolden',
          'Brown',
          'BrownDark',
          'PastelPink',
          'Platinum',
          'Red',
          'SilverGray'
        ],
        accessoriesTypes: [
          'Blank',
          'Kurt',
          'Prescription01',
          'Prescription02',
          'Round',
          'Sunglasses',
          'Wayfarers',
        ],
        clotheTypes: [
          'BlazerShirt',
          'BlazerSweater',
          'CollarSweater',
          'GraphicShirt',
          'Hoodie',
          'Overall',
          'ShirtCrewNeck',
          'ShirtScoopNeck',
          'ShirtVNeck'
        ],
        clotheColors: [
          'Black',
          'Blue01',
          'Blue02',
          'Blue03',
          'Gray01',
          'Gray02',
          'Heather',
          'PastelBlue',
          'PastelGreen',
          'PastelOrange',
          'PastelRed',
          'PastelYellow',
          'Pink',
          'Red',
          'White'
        ],
        facialHairColors: [
          'Auburn',
          'Black',
          'Blonde',
          'BlondeGolden',
          'Brown',
          'BrownDark',
          'PastelPink',
          'Platinum',
          'Red',
          'SilverGray'
        ],
        facialHairTypes: [
          'Blank',
          'BeardMedium',
          'BeardLight',
          'BeardMajestic',
          'MoustacheFancy',
          'MoustacheMagnum'
        ],
        eyeTypes: [
          'Close',
          'Cry',
          'Default',
          'Dizzy',
          'EyeRoll',
          'Happy',
          'Hearts',
          'Side',
          'Squint',
          'Surprised',
          'Wink',
          'WinkWacky'
        ],
        eyebrowTypes: [
          'Angry',
          'AngryNatural',
          'Default',
          'DefaultNatural',
          'FlatNatural',
          'RaisedExcited',
          'RaisedExcitedNatural',
          'SadConcerned',
          'SadConcernedNatural',
          'UnibrowNatural',
          'UpDown',
          'UpDownNatural'
        ],
        mouthTypes: [
          'Concerned',
          'Default',
          'Disbelief',
          'Eating',
          'Grimace',
          'Sad',
          'ScreamOpen',
          'Serious',
          'Smile',
          'Tongue',
          'Twinkle',
          //'Vomit'
        ],
        skinColors: [
          'Tanned',
          //'Yellow',
          'Pale',
          'Light',
          'Brown',
          'DarkBrown',
          'Black'
        ],
        graphicTypes: [
            'Blank',
          'Bat',
          //'Cumbia',
          'Deer',
          'Diamond',
          'Hola',
          'Pizza',
          //'Resist',
          //'Selena',
          'Bear',
          //'SkullOutline',
          //'Skull'
        ],
        hairColors: [
          'Auburn',
          'Black',
          'Blonde',
          'BlondeGolden',
          'Brown',
          'BrownDark',
          'PastelPink',
          'Platinum',
          'Red',
          'SilverGray'
        ],
      }
    }
  },
  computed: {
    hairOrHatColors() {
      if(this.avatar.topType.includes('Hair')) {
        return this.options.hairColors
      }
      return this.options.clotheColors
    },
    hairColorDisabled() {
      return this.avatar.topType === 'Eyepatch';
    }
  },
  mounted() {
    this.loadSettings()
  },
  methods: {
    random() {
      this.avatar.skinColor = this.getRandom(this.options.skinColors)
      this.avatar.topType = this.getRandom(this.options.topTypes)
      this.avatar.topColor = this.getRandom(this.options.hairColors)
      this.avatar.accessoriesType = this.getRandom(this.options.accessoriesTypes)
      this.avatar.facialHairType = this.getRandom(this.options.facialHairTypes)
      this.avatar.facialHairColor = this.getRandom(this.options.facialHairColors)
      this.avatar.clotheType = this.getRandom(this.options.clotheTypes)
      this.avatar.clotheColor = this.getRandom(this.options.clotheColors)
      this.avatar.graphicType = this.getRandom(this.options.graphicTypes)
      this.avatar.eyeType = this.getRandom(this.options.eyeTypes)
      this.avatar.eyebrowType = this.getRandom(this.options.eyebrowTypes)
      this.avatar.mouthType = this.getRandom(this.options.mouthTypes)
    },
    getRandom(list) {
      return list[Math.floor((Math.random() * list.length))];
    },
    async save() {
      try {
        const {username} = this.$store.state.account
        const fileHandle = await window.showSaveFilePicker({ suggestedName: `nyssma-avatar-${username}.svg`,
          types: [{
            description: 'SVG',
            accept: {
              'image/svg+xml': ['.svg'],
            },
          }]});
        this.avatar.isCircle = false
        const fileStream = await fileHandle.createWritable();
        const svgData = this.$refs['avatar-maker'].$el.outerHTML
        const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
        const svgUrl = URL.createObjectURL(svgBlob);

        await fileStream.write(svgBlob)
        await fileStream.close();
      }
      catch(e) {
        //
      }
      finally {
        this.avatar.isCircle = true
        this.saveLastSettings()
      }
    },
    async setAvatar() {
      try {
        this.avatar.isCircle = false
        this.$nextTick(() => {
          const {username} = this.$store.state.account
          const svgData = this.$refs['avatar-maker'].$el.outerHTML
          const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});

          const reader = new FileReader();
          reader.readAsDataURL(svgBlob);
          reader.onloadend = () => {
            this.$emit('set', {
              name: `nyssma-avatar-${username}.svg`,
              src: reader.result,
              size: svgBlob.size
            })
          }
        })
      }
      catch(e) {
        //
      }
      finally {
        this.$nextTick(() => {
          this.avatar.isCircle = true
          this.saveLastSettings()
        })
      }
    },
    loadSettings() {
      const {username} = this.$store.state.account
      if(username) {
        const storedItem = localStorage.getItem(`nyssma.avatar.${username}`)
        if(storedItem) {
          this.avatar = JSON.parse(storedItem)
        }
      }
    },
    saveLastSettings() {
      const {username} = this.$store.state.account
      localStorage.setItem(`nyssma.avatar.${username}`, JSON.stringify(this.avatar))
    }
  }
}
</script>

<style scoped>
  .avatar-wrapper {
    display: flex;
    justify-content: center;
  }
  .avatar-wrapper svg {
    max-height: 350px;
    text-align: center;
  }
</style>
