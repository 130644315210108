<template>
  <b-modal id="avatar-modal" ref="avatar-modal" :title="title" :size="createMode ? 'xl' : 'lg'" lazy centered ok-title="Add" :hide-footer="createMode" @shown="showAvatar">
    <template v-if="createMode" >
      <avatar-maker @set="setAvatar"/>
    </template>
    <template v-if="!createMode">
      <dropzone ref="dropzone" :image="avatar" @set="setAvatar"></dropzone>
    </template>
    <template v-if="!createMode" #modal-footer>
      <b-row align-content="center">
        <b-col align-self="center" class="text-right py-0">
          <b-button variant="danger" size="sm" @click="removeAvatar">Remove</b-button>
          <b-button variant="default" size="sm" @click="$bvModal.hide('avatar-modal')">Close</b-button>
        </b-col>
      </b-row>
    </template>

  </b-modal>
</template>

<script>
  import Avatar from '@/models/avatar'
  import Dropzone from '@/components/Avatar/DropZone.vue';
  import AvatarMaker from '@/views/account/AvatarMaker.vue';

  export default {
    name: 'AvatarModal',
    components: {
      AvatarMaker,
      Dropzone,
    },
    props: {
      title: {
        type: String,
        required: true
      },
      avatar: {
        type: Object,
        default() {
          return new Avatar()
        }
      },
      createMode: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      setAvatar(avatar) {
        this.$emit('update-avatar', avatar)
      },
      updateAvatarSrc(src) {
        this.avatar.src = src
        this.setAvatar(this.avatar)
      },
      showAvatar() {
        try {
          this.$refs.dropzone.loadThumbnail()
        }
        catch(e) {
          //
        }
      },
      removeAvatar() {
        this.$refs.dropzone.removeAvatar()
      },
    }
  }
</script>
