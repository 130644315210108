<template>
  <div>
    <b-alert show variant="primary" >
      <b-icon-info-circle class="mr-1"/>
      <span class="font-small-3">
        All Storage settings are saved in your internet browser, not on the server.
      </span>

      <ul class="pl-3 font-small-3 font-weight-normal">
        <li >
          Local Storage settings persist even if the tab/browser has been closed.
        </li>
        <li>
          Session Storage settings are not shared across browser tabs and will be lost once the tab/browser has been closed.
        </li>
      </ul>
    </b-alert>

    <b-card>
      <!-- Search -->
      <template>
        <b-row>
          <b-col class="pr-2">
            <b-input-group class="input-group-merge">
              <b-form-input
                  v-model="search.value"
                  :debounce="250"
                  autocomplete="off"
                  placeholder="Search..."
                  class="search-product"
              />
              <b-input-group-append v-if="search.value" is-text>
                <feather-icon icon="XIcon" class="text-muted" @click="search.value = ''" />
              </b-input-group-append>
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>

            </b-input-group>
          </b-col>
          <b-col cols="auto" align-self="center" class="pl-0">
            <b-dropdown id="preference-options" v-b-tooltip="'Options'" variant="link" no-caret toggle-class="p-0 ml-1" right>
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
              </template>
              <template>
                <b-dropdown-item id="page-layout-option-print" @click="clearLocalSettingsAll()"> <!--print('page-layout-content')-->
                  <feather-icon icon="PrinterIcon"/>
                  <span class="align-middle ml-50">Clear Local Settings</span>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </b-col>
        </b-row>
      </template>

      <b-overlay :show="routes.loading" :opacity="1" variant="white" class="mt-1">
        <label class="section-label">Routes</label>
        <b-row>
          <b-col v-for="(route, key) in filteredRoutes" :key="key" class="col-12 col-xl-6 col-xxl-4">
            <b-card no-body bg-variant="light"
                    class="p-1 border">
              <b-row class="mb-1">
                <b-col align-self="center">
                  <b-card-title class="mb-0">
                    {{ route.title }}
                    <small>
                      ({{ route.path }})
                    </small>
                  </b-card-title>
                </b-col>
                <b-col align-self="center" cols="auto">
                  <b-checkbox v-model="route.enabled"
                              switch size="sm"
                              title="enabled/disabled"
                              class="route-toggle"
                              @input="toggle(route)"/>
                </b-col>
                <b-col align-self="start" cols="12">

                </b-col>
              </b-row>

              <!-- Local Storage -->
              <b-row>
                <b-col>
                  <b-card body-class="p-1" class="mb-1 border">
                    <b-row>
                      <b-col align-self="center">
                        <label class="section-label">Local Storage</label>
                      </b-col>
                      <b-col align-self="center" cols="auto">
                        <b-checkbox v-model="route.isLocalEnabled"
                                    :disabled="!route.enabled"
                                    switch size="sm"
                                    title="enabled/disabled"
                                    class="route-toggle"
                                    @input="toggle(route)"/>
                      </b-col>
                    </b-row>

                    <b-list-group v-if="route.settings.local" flush class="mt-1">
                      <b-list-group-item v-for="(value, key) in route.settings.local"
                                         :id="`${route.name}-${key}`" :key="key"
                                         :disabled="!route.enabled"
                                         class="d-flex justify-content-between align-items-center font-small-2 pr-75">
                        <span class="align-middle">
                           {{ key }}
                        </span>
                        <b-button size="sm" variant="link"
                                  :disabled="!route.enabled || !route.isLocalEnabled"
                                  title="Clear" class="btn-icon p-0"
                                  @click="clearLocalSettingKey(route, key)">
                          <b-icon-x-circle-fill />
                        </b-button>

                        <b-popover :target="`${route.name}-${key}`" triggers="hover" placement="top" custom-class="storage-pop">
                          <template #title>{{ key }}</template>
                          <template #default>
                            <template v-if="Array.isArray(value)">
                              <div v-for="(v, i) in value" :key="i" class="storage-pop-content">
                                <div v-for="(v2, k2, i2) in v" :key="i2">
                                  <span class="font-small-2">{{ k2.key }}</span>
                                  <template v-if="Array.isArray(v2)">
                                    <div>
                                      <li v-for="(v3, i3) in v" :key="i3" class="font-small-2">
                                        {{ v3 }}
                                      </li>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <span class="font-small-2">{{ v2 ? v2 : 'null' }}</span>
                                  </template>
                                </div>
                              </div>
                            </template>
                            <template v-else>
                              <div v-for="(v, k, i) in value" :key="i" class="storage-pop-content">
                                <span class="font-small-2">{{ k }}</span>
                                <template v-if="Array.isArray(v)">
                                  <div>
                                    <li v-for="(v2, i2) in v" :key="i2" class="font-small-2">
                                      {{ v2 }} v2
                                    </li>
                                  </div>
                                </template>
                                <template v-else-if="isObject(v)">
                                  <div v-for="(v2, k2, i2) in v" :key="i2" class="font-small-2">
                                    {{ k2 }}
                                    <template v-if="isObject(v)">
                                      <div>
                                        <li v-for="(v3, k3, i3) in v2" :key="i3" class="font-small-2">
                                          {{ k3 }} - {{ v3 }}
                                        </li>
                                      </div>
                                    </template>
                                    <template v-else>
                                      <span class="font-small-2">{{ v2 !== null ? v2 : 'null' }}</span>
                                    </template>
                                  </div>
                                </template>
                                <template v-else>
                                  <span class="font-small-2">{{ v !== null ? v : 'null' }}</span>
                                </template>
                              </div>
                            </template>
                          </template>
                        </b-popover>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </b-col>
              </b-row>

              <!-- Session Storage -->
              <b-row v-if="routeCanCache(route.name)">
                <b-col>
                  <b-card class="border mb-0" body-class="p-1">
                    <b-row>
                      <b-col align-self="center">
                        <label class="section-label">Session Storage</label>
                      </b-col>
                      <b-col align-self="center" cols="auto">
                        <b-checkbox v-model="route.isCacheEnabled"
                                    :disabled="!route.enabled"
                                    switch size="sm" title="enabled/disabled"
                                    class="route-toggle"
                                    @input="toggle(route)"/>
                      </b-col>
                    </b-row>

                    <b-list-group v-if="wasCreated(route)" flush>
                      <b-list-group-item v-for="(value, key) in route.settings.session" :key="key"
                                         :disabled="!route.enabled"
                                         class="d-flex justify-content-between font-small-2">
                        <span>{{ key }}</span>
                        <span>{{ Array.isArray(value) ? value.length : value }}</span>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Fuse from 'fuse.js';
import _ from 'lodash';
import {canNavigate} from '@/libs/acl/routeProtection';

export default {
  components: {
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      routes: {
        items: this.loadRoutes(),
        loading: true
      },
      search: {
        value: null,
      }
    }
  },
  computed: {
    filteredRoutes() {
      if(_.isEmpty(this.search.value)) {
        return this.routes.items.slice().sort((a, b) => a.title?.localeCompare(b.title))
      }

      const keys = ['name', 'path']
      const fuse = new Fuse(this.routes.items, {
        useExtendedSearch: true,
        threshold: 0.3,
        keys: keys
      })

      /** Build the Search Query  **/
      const query = { $and: [ ] }
      if(this.search.value) {
        const or = []
        keys.forEach(key => {
          or.push({[key]: `'${this.search.value}` })
        })
        query.$and.push({ $or: or }) //Push our $or clauses into the $and clause
      }
      return fuse.search(query).map(({ item }) => item).sort((a, b) => a.title.localeCompare(b.title))
    },
  },
  mounted() {
    //todo - do something so that if loading from local storage we check to see if the router has new routes, if so add them to local
    this.routes.items.forEach(route => {
      route.settings.local = this.getLocalSetting(route.name)
      route.settings.session = this.getSessionSetting(route.name)
    })
    this.routes.loading = false
  },
  methods: {
    loadRoutes() {
      let map
      const routes = this.$store.getters['storageLocal/getRoutes']

      if(routes) {
        /** Map Routes From Local Storage **/
        map = routes.map(route => ({
          name: route.name,
          path: route.path,
          title: route.title,
          enabled: route.enabled,
          isCacheEnabled: route.isCacheEnabled,
          isLocalEnabled: route.isLocalEnabled,
          settings: { local: { }, session: { } }
        }))

        /** Add Missing Routes - in case routes changed or new ones were added **/
        this.getMappedAndFilteredRouterRoutes().forEach(route => {
           const hasRoute = !!map.find(mappedRoute => mappedRoute.name === route.name)
           if(!hasRoute) {
             map.push(route)
           }
        })
      }
      else {
        /** Map Routes From Router - they didn't exist in local storage **/
        map = this.getMappedAndFilteredRouterRoutes()
      }

      this.$store.dispatch('storageLocal/updateRoutes', {
        key: 'routes',
        value: map
      })
      return map;
    },
    innerItem(key, object) {
      const last = key.split('.').pop()
      return object[last]
    },
    getLocalSetting(key) {
      return this.$store.getters['storageLocal/getItem'](key)
    },
    clearLocalSetting(route) {
      route.settings.local = null
      this.$store.dispatch('storageLocal/removeItem', {
        key: route.name,
      })
    },
    clearLocalSettingsAll() {
      this.$store.commit('storageLocal/clearAll')
    },
    clearLocalSettingKey(route, key) {
      this.$delete(route.settings.local, key)
      this.$store.dispatch('storageLocal/updateItem', {
        key: route.name,
        value: route.settings.local
      })
    },
    getSessionSetting(key) {
      const innerKey = key.split('.').pop();
      const isExpired = this.$store.getters[`${innerKey}/isExpired`] //forces the state to recheck and update the value
      return this.$store.getters[`${innerKey}/get`]
    },
    toggle(route) {
      this.$store.dispatch('storageLocal/updateRoutes', {
        key: 'routes',
        value: this.routes.items.map(r => ({
          name: r.name,
          path: r.path,
          title: r.title,
          enabled: r.enabled,
          isCacheEnabled: r.isCacheEnabled,
          isLocalEnabled: r.isLocalEnabled,
        }))
      })
    },
    wasCreated(route) {
      return route?.settings?.session?.createdAt
    },
    isObject(item) {
      return item?.constructor?.name === 'Object'
    },
    routeCanCache(name) {
      return this.$store.hasModule(name)
    },

    getMappedAndFilteredRouterRoutes() {
      return this.$router?.options?.routes?.filter(route => route.meta?.hasSettings && this.$can('read', route.meta.subject)).map(route => ({
        name: route.name,
        path: route.path,
        title: route.meta.pageTitle,
        enabled: route.name === 'dashboard',
        isCacheEnabled: true,
        isLocalEnabled: true,
        settings: { local: { }, session: { } }
      })).sort((a, b) => a.name.localeCompare(b.name)) || []
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.storage-pop {
  //min-width: 16rem;
  //max-width: 25vw;
  width: 100%;
}
.storage-pop-content {
  padding: .5em;
  border-bottom: 1px solid #ebe9f1;
  display: flex;
  justify-content: space-between;
}

.pr-75 {
  padding-right: 0.75rem;
}

.storage-pop-content:last-child{
  border: 0;
}

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.route-toggle .custom-control-label {
    padding: 0!important;
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}

.custom-switch.b-custom-control-sm .custom-control-label::before, .input-group-sm .custom-switch .custom-control-label::before {
  top: 0.5rem!important;
}

.custom-switch.b-custom-control-sm .custom-control-label::after, .input-group-sm .custom-switch .custom-control-label::after {
  top: calc( 0.5rem + 2px )!important;
}
</style>
