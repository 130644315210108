<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-avatar v-if="hasCustomAvatar(user.avatar)" v-b-modal.avatar-modal variant="primary" size="8em" badge-variant="white">
          <b-img fluid fluid-grow :src="user.avatar.src" :alt="user.avatar.name"></b-img>
<!--          <template #badge>
            <b-icon :icon="enabledIcon(user.state.enabled)" :variant="enabledIconVariant(user.state.enabled)"></b-icon>
          </template>-->
        </b-avatar>
        <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" >
          <font-awesome-icon icon="fa-solid fa-user" size="3x"></font-awesome-icon>
<!--          <template #badge>
            <b-icon :icon="enabledIcon(user.state.enabled)" :variant="enabledIconVariant(user.state.enabled)"></b-icon>
          </template>-->
        </b-avatar>
        <avatar-modal title="Avatar" :avatar="user.avatar" :create-mode="options.createAvatar" @update-avatar="updateAvatar"/>
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-modal.avatar-modal variant="primary" size="sm" class="mb-75 mr-75" @click="options.createAvatar = false">Upload</b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-modal.avatar-modal variant="secondary" size="sm" class="mb-75 mr-75" @click="options.createAvatar = true">Create</b-button>


        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" size="sm" class="mb-75 mr-75" @click="resetAvatar()">Reset</b-button>

        <b-card-text>
          If you don't have an image to upload, try creating one!
        </b-card-text>

      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
      <b-form class="mt-2" @submit.stop.prevent="handleSubmit(updateUser)">

        <!-- Name -->
        <b-row>
          <b-col sm="12" md="6">
            <validation-provider v-slot="validationContext" vid="first-name" name="First Name" rules="required">
              <b-form-group label="First Name" label-for="first-name" :invalid-feedback="validationContext.errors[0]">
                <b-form-input v-model="user.name.first" name="first-name" placeholder="First Name" :state="getValidationState(validationContext)"/>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="12" md="6">
            <validation-provider v-slot="validationContext" vid="last-name" name="Last Name" rules="required">
              <b-form-group label="Last Name" label-for="last-name" :invalid-feedback="validationContext.errors[0]">
                <b-form-input v-model="user.name.last" name="last-name" placeholder="Last Name" :state="getValidationState(validationContext)"/>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- alert -->
          <b-col v-if="user.state.status === 'FORCE_CHANGE_PASSWORD'" cols="12" class="mt-75">
            <b-alert show variant="warning">
              Your email is not confirmed
            </b-alert>
          </b-col>
          <!--/ alert -->


        </b-row>

        <!-- Address -->
        <template>
          <hr class="my-1">
          <b-row>
            <b-col sm="12" md="">
              <validation-provider v-slot="validationContext" vid="line1" name="Line 1" :rules="isTeacherGroup() ? 'required' : null">
                <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                  <b-form-input id="line1-input" v-model="user.address.line1" :state="getValidationState(validationContext)"></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12" md="">
              <validation-provider v-slot="validationContext" vid="line2" name="Line 2" rules="">
                <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                  <b-form-input id="line2-input" v-model="user.address.line2" :state="getValidationState(validationContext)"></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="6" lg="4">
              <validation-provider v-slot="validationContext" vid="city" name="City" :rules="isTeacherGroup() ? 'required' : null">
                <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                  <b-form-input id="city-input" v-model="user.address.city" :state="getValidationState(validationContext)"></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12" md="6" lg="4">
              <validation-provider v-slot="validationContext" vid="county" name="County" :rules="isTeacherGroup() ? 'required' : null">
                <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                  <v-select id="county-input"
                            v-model="user.address.county"
                            :options="options.counties"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            class="w-100"/>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6" md="6" lg="2">
              <validation-provider v-slot="validationContext" vid="state" name="State" :rules="isTeacherGroup() ? 'required' : null">
                <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                  <b-form-input id="state-input" v-model="user.address.state" :state="getValidationState(validationContext)"></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6" md="6" lg="2">
              <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="isTeacherGroup() ? 'required' : null">
                <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
                  <b-form-input id="zip-code-input" v-model="user.address.zip" :state="getValidationState(validationContext)"></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </template>


        <!-- Save / Clear -->
        <b-row>
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mt-2 mr-1">
              Save changes
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import avatar from '@/mixins/avatar.mixin'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import notify from '@/mixins/notify.mixin';
import Avatar from '@/models/avatar';
import {API, graphqlOperation} from 'aws-amplify';
import { updateUser } from '@/graphql/mutations';
import { getDistrict, getSchool, listZones} from '@/graphql/queries';

export default {
  components: {
    AvatarModal,
    vSelect
  },
  directives: {
    Ripple,
  },
  mixins: [ avatar, notify ],
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      user: {
        id: this.generalData.id,
        name: {
          first: this.generalData.name?.first || null,
          last: this.generalData.name?.last || null
        },
        address: {
          line1: this.generalData.address?.line1 || null,
          line2: this.generalData.address?.line2 || null,
          city: this.generalData.address?.city || null,
          county: this.generalData.address?.county || null,
          state: this.generalData.address?.state || null,
          zip: this.generalData.address?.zip || null
        },
        avatar: {
          src: this.$store.state?.account?.avatar?.src || null,
          name: this.$store.state?.account?.avatar?.name || null,
          size: this.$store.state?.account?.avatar?.size || null
        },
        groups: this.generalData.groups || [],
        state: this.generalData.state
      },
      options: {
        counties: [],
        createAvatar: false
      }
    }
  },
  computed: {
    counties() {
      return this.user.district?.zone ? this.options.districts.find(district => district.id === this.user.district.id).zone.counties : []
    }
  },
  async created() {
    if(this.generalData.connections.school) {
      await this.getSchoolZones(this.generalData.connections.school)
    }
    else if(this.generalData.connections.district) {
      await this.getDistrictZones(this.generalData.connections.district)
    }
    else {
      await this.listZones()
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    isTeacherGroup() {
      return this.user.groups.includes('Teacher')
    },
    async getDistrictZones(id) {
      const response = await API.graphql(graphqlOperation(getDistrict, { id: id }));
      const district = response.data.getDistrict
      if(district) {
        this.options.counties = district.zone.counties
      }
    },
    async getSchoolZones(id) {
      const response = await API.graphql(graphqlOperation(getSchool, { id: id }));
      const school = response.data.getSchool
      this.options.counties = school.district.zone.counties
    },
    async listZones() {
      const response = await API.graphql(graphqlOperation(listZones));
      const zones = response.data.listZones.items;
      zones.forEach(zone => {
        this.options.counties.push(...zone.counties)
      })
    },

    async patchUser(input) {
      try {
        await API.graphql(graphqlOperation(updateUser, { input: input } ));
        this.notify({ title: 'Success', text: 'Account was successfully updated', icon: 'fas fa-user', variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Account failed to update', icon: 'fas fa-user', variant: 'danger'});
      }
    },

    async updateUser() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchUser({
          id: this.user.id,
          name: this.user.name,
          address: this.user.address,
        })
        this.$emit('update:data', {
          id: this.user.id,
          name: this.user.name,
          address: this.user.address,
        })
      }
      else {
        this.notify({ title: 'Warning', text: 'District failed to update. Missing required fields.', icon: 'fas fa-server', variant: 'warning'});
      }
    },

    resetAvatar() {
      // eslint-disable-next-line no-shadow
      const avatar = new Avatar();
      this.user.avatar = avatar
      this.updateAvatar(avatar)
      this.$store.dispatch('account/setAvatar', avatar)
    },

    // eslint-disable-next-line no-shadow
    async updateAvatar(avatar) {
      this.user.avatar = avatar;
      await this.patchUser({ id: this.user.id, avatar: this.user.avatar }).then(async () => {
        await this.$store.dispatch('account/setAvatar', avatar)
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>
