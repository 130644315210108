<template>
  <b-card>

    <b-alert show variant="primary" dismissible>
      <b-icon-exclamation-circle class="align-middle"/>
      <span class="align-middle ml-50">Changing Your Email Address</span>
      <p class="font-small-3">
        In order to update your email address, you will need to verify the change before it can take effect.
      </p>

    </b-alert>
    <!-- alert/error -->
    <b-alert :show="alert.show" :variant="alert.variant" dismissible>
      <b-icon-info-circle class="align-middle"/>
      <span class="align-middle ml-50">Success</span>
      <p class="font-small-3">
        {{ alert.text }}
      </p>
    </b-alert>

    <!-- form -->
    <validation-observer ref="emailChange" tag="form">
      <b-row>
        <!-- Current Email -->
        <b-col md="12">
          <validation-provider v-slot="validationContext" ref="currentEmail" vid="currentEmail" name="Current Email Address" :rules="rules.passwordOld">
            <b-form-group label="Current Email Address" label-for="account-current-email" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
              <b-form-input id="account-old-password" v-model="data.email" disabled/>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <!-- new email -->
        <b-col align-self="start">
          <validation-provider v-slot="validationContext" ref="email" vid="email" name="New Email Address" :rules="rules.email">
            <b-form-group label-for="account-new-password" label="New Email Address" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
              <b-form-input
                id="account-new-password"
                v-model="email"
                name="new-password"
                placeholder="Email Address"
                :disabled="state.sent"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- send code -->
        <b-col cols="3" align-self="start">
          <b-button :variant="!state.sent ? 'primary' : 'success'"
                    block
                    :disabled="email === null || state.sent"
                    style="margin-top: 1.4rem;"
                    @click="updateEmailAndSendCode">
            {{ !state.sent ? 'Send Verification Code' : 'Check Your Email' }}
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="state.sent">
        <!-- new email -->
        <b-col align-self="start">
          <validation-provider v-slot="validationContext" ref="code" vid="code" name="Verification Code" :rules="rules.code">
            <b-form-group label-for="account-new-password" label="Verification Code" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
              <b-form-input
                  id="account-new-password"
                  v-model="code"
                  name="new-password"
                  placeholder="Code"
                  :disabled="state.verified"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- send code -->
        <b-col cols="3" align-self="start">
          <b-button variant="primary"
                    block
                    :disabled="state.verified"
                    style="margin-top: 1.4rem;"
                    @click="verifyEmailValidationCode">Verify and Save</b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { Auth } from 'aws-amplify';
import Ripple from 'vue-ripple-directive'
import notify from '@/mixins/notify.mixin';

export default {
  directives: {
    Ripple,
  },
  mixins: [ notify ],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      email: null,
      code: null,
      rules: {
        email: { required: true, email: true, is_not: this.data.email },
      },
      icon: 'fas fa-key',
      alert: {
        text: null,
        variant: null,
        show: false
      },
      state: {
        sent: false,
        verified: false
      },
    }
  },
  computed: {
    saveButtonText() {
      return this.state.updating ? 'Saving...' : 'Save'
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateEmailAndSendCode() {
      await this.$refs?.email?.validate().then((isValid) => {
        if(isValid) {
          Auth.currentAuthenticatedUser()
              .then(user => Auth.updateUserAttributes(user, {
                email: this.email,
              }))
              .then(data => {
                this.state.sent = true
                this.notify({ title: 'Success', text: 'Verification Code Sent', icon: this.icon, variant: 'success'});
              })
              .catch(err => {
                console.error(err)
                this.alert = { show: true, variant: 'danger', text: err }
                this.notify({ title: 'Error', text: 'Failed to Send Verification Code', icon: this.icon, variant: 'danger'});
              })
        }
        else {
          this.notify({ title: 'Warning', text: 'Missing required fields', icon: this.icon, variant: 'warning'});
        }
      })
    },
    async verifyEmailValidationCode() {
      await Auth.verifyCurrentUserAttributeSubmit('email', this.code)
          .then(() => {
            this.state.verified = true
            this.$emit('update:email', this.email)

            this.alert = { show: true, variant: 'success', text: 'Your Email Address was successfully updated.'}
            this.notify({ title: 'Success', text: 'Email was successfully updated.', icon: this.icon, variant: 'success'});

            this.email = null
            this.code = null
            this.state.sent = false
            this.state.verified = false
            this.$refs.email.reset()
          })
          .catch((e) => {
            this.state.verified = false
            this.notify({ title: 'Error', text: 'Failed to update email address', icon: this.icon, variant: 'danger'});
          });
    }
  },
}
</script>
