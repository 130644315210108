<template>
  <b-card>
    <template v-if="currentUser.attributes.loaded">
      <b-alert :show="!currentUser.attributes.email_verified" variant="danger" class="mb-2">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        <span class="align-middle ml-50">Unverified Account!</span> <br/>
        <p class="font-small-3">
          Please verify your account. If you forget your password you will not be able to recover it yourself.<br/>
          <small>
            A Verification Code email can take up to 15 minutes to be sent. If you have not received an email by then,
            please refresh this page and click the Send Code button again.
          </small>
        </p>
      </b-alert>

      <b-alert :show="currentUser.attributes.email_verified" variant="success" class="mb-0">
        <font-awesome-icon icon="fa-solid fa-circle-info" />
        <span class="align-middle ml-50">Verified Account!</span> <br/>
        <small>Your account is completely verified. No further actions are needed.</small>
      </b-alert>
    </template>

    <template v-if="currentUser.attributes.loaded">
      <validation-observer ref="verifyAttribute" tag="form">
        <b-row v-if="!currentUser.attributes.email_verified">
          <b-col align-self="center">
              <b-form-group label="E-mail" label-for="account-e-mail">
                <b-form-input v-model="user.email" name="email" placeholder="Email" disabled/>
              </b-form-group>
          </b-col>
          <b-col align-self="center">
            <validation-provider #default="{ errors }" vid="email" name="Verification" :rules="rules.email">
              <b-form-group label="Verification Code" label-for="account-e-mail" >
                <b-form-input v-model="code.email" name="email" placeholder="Email Verification Code" trim :state="errors.length > 0 ? false : null"/>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col align-self="center" cols="auto">
            <b-button v-if="!state.email.sent" :disabled="state.email.sending" class="mr-1" @click="sendVerificationCode('email')">Send Code</b-button>
            <b-button :disabled="state.phone.verifying" variant="primary" @click="verifyAttributeCode('email', code.email)">Verify</b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </template>
  </b-card>
</template>

<script>

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import {VueMaskDirective} from 'v-mask';
import {Auth} from 'aws-amplify';
import notify from '@/mixins/notify.mixin';

export default {
  components: {
    vSelect,
    flatPickr,
    Cleave,
  },
  directives: {
    Ripple,
    mask: VueMaskDirective
  },
  mixins: [ notify ],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      currentUser: {
        attributes: {
          loaded: false,
          email_verified: null
        }
      },
      code: {
        email: null
      },
      user: {
        id: this.informationData.id,
        username: this.informationData.username,
        email: this.informationData.email,
        phone: this.informationData.phone,
      },
      rules: {
        email: { required: true, min: 6 },
      },
      icon: 'fas fa-info-circle',
      error: {
        text: null,
        visible: false
      },
      state: {
        email: {
          sent: false,
          verifying: false
        },
        phone: {
          sending: false,
          sent: false,
          verifying: false
        }
      },
    }
  },
  computed: {
    awsPhoneNumber() {
      return this.user.phone?.replaceAll(/[^+\d]+/g, '')
    },
  },
  async beforeCreate() {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const { attributes } = user;
    this.currentUser.attributes.email_verified = attributes.email_verified
    this.currentUser.attributes.loaded = true
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async sendVerificationCode(attr) {
      const state = (attr === 'email' ? this.state.email : this.state.phone)
      state.sending = true
      Auth.verifyCurrentUserAttribute(attr)
          .then(() => {
            state.sent = true
            this.notify({ title: 'Success', text: 'Verification code sent', icon: this.icon, variant: 'success'});
          })
          .catch((e) => {
            console.error(e)
            this.error.visible = true
            this.error.text = e.message
            this.notify({ title: 'Error', text: 'Failed to  send verification code', icon: this.icon, variant: 'danger'});
          });
      state.sending = false
    },
    async verifyAttributeCode(attr, code) {
      this.state.updating = true
      await this.$refs?.verifyAttribute?.validate().then((isValid) => {
        if(isValid) {
          Auth.verifyCurrentUserAttributeSubmit(attr, code)
              .then(() => {
                this.currentUser.attributes.email_verified = true
                this.notify({ title: 'Success', text: 'Verified', icon: this.icon, variant: 'success'});
                this.$emit('verified', attr)
              })
              .catch(e => {
                console.error(e)
                this.error.visible = true
                this.error.text = e.message
                this.notify({ title: 'Error', text: 'Failed to  verify', icon: this.icon, variant: 'danger'});
              });
        }
        else {
          this.notify({ title: 'Warning', text: 'Missing required fields', icon: this.icon, variant: 'warning'});
        }
      })
      this.state.updating = false
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
