<template>
  <div>
    <b-tabs v-if="!loading" v-model="currentTab.index" vertical pills
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left">

      <!-- Details -->
      <b-tab lazy @click="changeTab('details')">
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50"/>
          <span class="font-weight-bold">Account Details</span>
        </template>

        <account-setting-information v-if="user && currentTab.key === 'details'"
                                     :information-data="{
                                        id: user.id,
                                        username: user.username,
                                        email: user.email,
                                        phone: user.phone
                                     }"
                                     @update:phone="user.phone = $event"/>
      </b-tab>

      <!-- Verification -->
      <b-tab lazy @click="changeTab('verify')">
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50"/>
          <span class="font-weight-bold">Account Verification</span>
        </template>
        <account-setting-verification v-if="user && currentTab.key === 'verify'"
                                      :information-data="{ email: user.email, phone: user.phone }"
                                      @verified="verified"/>
      </b-tab>


      <!-- Email -->
      <b-tab lazy @click="changeTab('email')">
        <template #title>
          <feather-icon icon="MailIcon" size="18" class="mr-50"/>
          <span class="font-weight-bold">Change Email Address</span>
        </template>
        <account-setting-email v-if="currentTab.key === 'email'"
                               :data="{ email: user.email }"
                               @update:email="user.email = $event"/>
      </b-tab>

      <!-- Password -->
      <b-tab lazy @click="changeTab('password')">
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50"/>
          <span class="font-weight-bold">Change Password</span>
        </template>
        <account-setting-password v-if="currentTab.key === 'password'"/>
      </b-tab>

      <!-- General -->
      <b-tab lazy @click="changeTab('general')">
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50"/>
          <span class="font-weight-bold">General</span>
        </template>

        <account-setting-general v-if="user && currentTab.key === 'general'"
                                 :general-data="{
                                   id: user.id,
                                   name: user.name,
                                   address: user.address,
                                   avatar: user.avatar,
                                   groups: user.groups,
                                   connections: { district: user.districtUsersId, school: user.schoolUsersId },
                                   state: user.state
                                 }"
                                 @update:data="updateUserFromGeneralTab"/>
      </b-tab>

      <!-- Theme -->
      <b-tab lazy @click="changeTab('theme')">
        <template #title>
          <feather-icon icon="FeatherIcon" size="18" class="mr-50"/>
          <span class="font-weight-bold">Theme</span>
        </template>
        <account-setting-theme v-if="currentTab.key === 'theme'" />
      </b-tab>

      <!-- Preferences -->
      <b-tab lazy @click="changeTab('preferences')">
        <template #title>
          <feather-icon icon="SettingsIcon" size="18" class="mr-50"/>
          <span class="font-weight-bold">Local Settings</span>
        </template>
        <account-setting-preferences v-if="currentTab.key === 'preferences'" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingEmail from './AccountSettingEmail.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingVerification from './AccountSettingVerification.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import AccountSettingTheme from './AccountSettingTheme.vue'
import AccountSettingPreferences from '@/views/account/AccountSettingPreferences.vue';
import {adminGetUser, adminListGroupsForUser} from '@/scripts/aws';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUser} from '@/graphql/queries';


export default {
  components: {
    AccountSettingPreferences,
    AccountSettingGeneral,
    AccountSettingEmail,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingVerification,
    AccountSettingTheme
  },
  data() {
    return {
      options: {},
      user: null,
      tabs: {
        details: { key: 'details', index: 0 },
        verify: { key: 'verify', index: 1 },
        email: { key: 'email', index: 2 },
        password: { key: 'password', index: 3 },
        general: { key: 'general', index: 4 },
        theme: { key: 'theme', index: 5 },
        preferences: { key: 'preferences', index: 6 }
      },
      currentTab: {
        key: 'details',
        index: 0
      },
      loading: true
    }
  },
  async beforeCreate() {
    /** Get Current User from Store **/
    const { account } = this.$store.state;

    /** Get User from AppSync **/
    const response = await API.graphql(graphqlOperation(getUser, { id: account.id }));
    const appSyncUser = response.data.getUser
    
    /** Get User from Cognito **/
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true })

    /** Build User **/
    this.user = {
      id: appSyncUser.id,
      username: appSyncUser.username,
      email: cognitoUser.attributes.email,
      name: appSyncUser.name,
      address: appSyncUser.address,
      phone: cognitoUser.attributes.phone_number,
      avatar: appSyncUser.avatar,
      districtUsersId: appSyncUser.districtUsersId,
      schoolUsersId: appSyncUser.schoolUsersId,
      state: {
        enabled: cognitoUser.Enabled,
        status: cognitoUser.UserStatus,
        email_verified: cognitoUser.attributes.email_verified,
        phone_verified: cognitoUser.attributes.phone_number_verified,
      }
    }
  },
  mounted() {
    if(this.$route.query.tab) {
      const tab = this.tabs[this.$route.query.tab]
      if(tab) {
        this.currentTab.key = tab.key
        this.currentTab.index = tab.index
      }
    }
    this.loading = false
  },
  methods: {
    changeTab(key) {
      if(this.$route.query?.tab !== key) {
        this.$router.replace({ name: 'account-settings', query: { tab: key } })
        this.currentTab.key = key
      }
    },
    verified(attribute) {
      if(attribute === 'email') { this.user.state.email_verified = true }
      if(attribute === 'phone_number') { this.user.state.phone_verified = true }
    },
    updateUserFromGeneralTab(user) {
      this.user.name = user.name
      this.user.address = user.address
    }
  }
}
</script>
