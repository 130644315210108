export default {
    methods: {
        hasCustomAvatar(avatar) {
            return avatar !== undefined && avatar !== null && avatar.src;
        },
        enabledIcon(enabled) {
            if(enabled === undefined || enabled === null) return 'question-circle-fill'
            return enabled ? 'check-circle-fill' : 'x-circle-fill'
        },
        enabledIconVariant(enabled) {
            if(enabled === undefined || enabled === null) return 'warning'
            return enabled ? 'success' : 'danger'
        },
        avatarText(value) {
            if (!value) return ''
            const nameArray = value.split(' ')
            return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
        },
        avatarVariant(status) {
            if (status === 'Partial Payment') return 'primary'
            if (status === 'Paid') return 'danger'
            if (status === 'Downloaded') return 'secondary'
            if (status === 'Draft') return 'warning'
            if (status === 'Sent') return 'info'
            if (status === 'Past Due') return 'success'
            return 'primary'
        }
    }
}
