<template>
  <b-card>
    <!-- error -->
    <b-alert :show="error.visible" variant="danger">
      {{ error.text }}
    </b-alert>

    <!-- form -->
    <validation-observer ref="passwordChange" tag="form">
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <validation-provider v-slot="validationContext" vid="passwordOld" name="Old Password" :rules="rules.passwordOld">
            <b-form-group label="Current Password" label-for="account-old-password" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="passwordOld.value"
                  :type="passwordOld.type"
                  name="old-password"
                  placeholder="Current Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordOldIcon"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <validation-provider v-slot="validationContext" vid="passwordNew" name="New Password" :rules="rules.passwordNew">
            <b-form-group label-for="account-new-password" label="New Password" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-new-password"
                  v-model="passwordNew.value"
                  :type="passwordNew.type"
                  name="new-password"
                  placeholder="New Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordNewIcon"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <validation-provider v-slot="validationContext" vid="passwordNewConfirm" name="New Password Confirm" :rules="rules.passwordNewConfirm">
            <b-form-group label-for="account-retype-new-password" label="Retype New Password" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-retype-new-password"
                  v-model="passwordNewConfirm.value"
                  :type="passwordNewConfirm.type"
                  name="retype-password"
                  placeholder="New Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordNewConfirmIcon"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :disabled="state.updating"
            @click="changePassword">
            <b-spinner v-if="state.updating" small/> {{ saveButtonText }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
            :disabled="state.updating">
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { Auth } from 'aws-amplify';
import Ripple from 'vue-ripple-directive'
import notify from '@/mixins/notify.mixin';

export default {
  components: { },
  directives: {
    Ripple,
  },
  mixins: [ notify ],
  data() {
    return {
      passwordOld: {
        value: '',
        type: 'password'
      },
      passwordNew: {
        value: '',
        type: 'password'
      },
      passwordNewConfirm: {
        value: '',
        type: 'password'
      },
      rules: {
        passwordOld: { required: true },
        passwordNew: { required: true, min: 8, confirmed: { target: 'passwordNewConfirm'} },
        passwordNewConfirm: { required: true, min: 8},
      },
      icon: 'fas fa-key',
      error: {
        text: null,
        visible: false
      },
      state: {
        updating: false
      },
    }
  },
  computed: {
    passwordOldIcon() {
      return this.passwordOld.type === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordNewIcon() {
      return this.passwordNew.type === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordNewConfirmIcon() {
      return this.passwordNewConfirm.type === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    saveButtonText() {
      return this.state.updating ? 'Saving...' : 'Save'
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    togglePasswordOld() {
      this.passwordOld.type = this.passwordOld.type === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordNew.type = this.passwordNew.type === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.this.passwordNewConfirm.type = this.this.passwordNewConfirm.type === 'password' ? 'text' : 'password'
    },
    async changePassword() {
      this.state.updating = true
      await this.$refs?.passwordChange?.validate().then((isValid) => {
         if(isValid) {
            Auth.currentAuthenticatedUser()
               .then(user => Auth.changePassword(user, this.passwordOld.value, this.passwordNew.value))
               .then(data => {
                 this.notify({ title: 'Success', text: 'Password updated', icon: this.icon, variant: 'success'});
               })
               .catch(err => {
                 console.error(err)
                 this.error.text = err
                 this.error.visible = true
                 this.notify({ title: 'Error', text: 'Password failed to update', icon: this.icon, variant: 'danger'});
               })
         }
         else {
           this.notify({ title: 'Warning', text: 'Missing required fields', icon: this.icon, variant: 'warning'});
         }
      })
      this.state.updating = false
    }
  },
}
</script>
