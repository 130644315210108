<template>
  <b-container>
    <b-row align-content="center">
      <b-col align-self="center" :class="colsClass ? colsClass : null">
        <vue-dropzone id="dropzone" ref="avatar" class="dropzone dropzone-single" :class="isNotNull ? 'dz-max-files-reached' : null" :options="options" @vdropzone-thumbnail="onThumbnail" @vdropzone-error="onError"></vue-dropzone>
      </b-col>
    </b-row>
    <b-row v-if="isNotNull">
      <b-col align-self="center" class="text-center p-1">
        <small v-if="image.name" class="text-muted">{{ image.name }} || {{ bytesToSize(image.size) }}</small>
        <b-container v-if="hasError">
          <h5 v-for="error in errors" :key="error" class="text-danger">{{ error }}</h5>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import vue2Dropzone from 'vue2-dropzone';
  import Avatar from '@/models/avatar';
  import notifyMixin from '@/mixins/notify.mixin';

  export default {
    name: 'AvatarDropzone',
    components: {
      vueDropzone: vue2Dropzone,
    },
    mixins: [notifyMixin],
    props: {
      image: {
        type: Object,
        required: true,
        default() {
          return new Avatar()
        }
      },
      colsClass: {
        type: String,
        required: false,
        default: ''
      }
    },
    data() {
      return {
        root: null,
        errors: [],
        options: {
          url: '/',
          maxFiles: 1,
          maxFilesize: 0.25, //mb
          thumbnailWidth: null,
          thumbnailHeight: null,
          previewTemplate: this.template(),
          acceptedFiles: 'image/*',
          autoProcessQueue: false //Don't upload with dropzone
        }
      }
    },
    computed: {
      isNotNull() {
        return this.image !== null && this.image.dataURL !== null;
      },
      hasError() {
        return this.errors.length > 0;
      }
    },
    mounted() {
      this.root = this;
    },
    methods: {
      template: function () {
        return `
          <div class="dz-preview dz-preview-single">
            <div class="dz-preview-cover">
                <img class="dz-preview-img" data-dz-thumbnail/>
            </div>
          </div>`;
      },
      onThumbnail(file) {
        //Clear Old Image Previews
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements( document.querySelectorAll('.dz-image-preview') );

        if(this.errors.length === 0 && file.dataURL !== this.image.src) {
          this.$emit('set', {
            name: file.name,
            src: file.dataURL,
            size: file.size
          })
        }
      },
      onError(file, message) {
        this.errors = [];
        if(message !== 'You can not upload any more files.' && message !== 'Upload canceled.') {
          this.errors.push(message)
          this.errors.push('Reverting to previously saved avatar...')
          this.notify(
            {
              title: 'Error',
              text: message,
              icon: 'fas fa-school',
              variant: 'danger'
            }
          );
        }
      },
      loadThumbnail() {
        if(this.image && this.image.src) {
          const mockFile = {
            name: this.image.name || null,
            size: this.image.size || -1,
            accepted: true,
            kind: 'image',
            upload: {
              filename: this.image.name || null,
            },
            dataURL: this.image.src,
          }
          this.$refs.avatar.manuallyAddFile(mockFile, mockFile.dataURL)
          this.$refs.avatar.dropzone.emit('thumbnail', mockFile, mockFile.dataURL)
          this.$refs.avatar.dropzone.emit('complete', mockFile)
        }
      },
      removeAvatar() {
        this.errors = [];
        this.$emit('set', {
          name: null,
          src: null,
          size: 0
        })
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements(document.querySelectorAll('.dz-image-preview'));
      },
      bytesToSize(a, b = 2) {
        //https://stackoverflow.com/a/18650828/2225183
        if(a === 0 ) return '0 Bytes';
        const c = b < 0 ? 0 : b;
        const d = Math.floor(Math.log(a) / Math.log(1024));
        // eslint-disable-next-line no-restricted-properties
        return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]}`
      }
    }
  }
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/dropzone.scss';
</style>
